// Libs
import { useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

// MUI Components
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

// Hooks
import useRoutes from 'hooks/useRoutes';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

// MUI Icons
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CopyAllIcon from '@mui/icons-material/CopyAllOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicatorOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import LinkOffIcon from '@mui/icons-material/LinkOffOutlined';
import RestoreIcon from '@mui/icons-material/Restore';

// Local Components
import ContextMenu from 'components/ContextMenu';
import MarkdownPreview from 'components/MarkdownPreview';
import DefectToolTip from 'components/Rules/DefectToolTip';
import AddPassageContextMenu from '../AddPassageContextMenu';

// Utilities
import { makeArray } from 'utils/enumerable';
import { carryForward, parentTypeName, typeName, passageContent } from 'utils/passages';

const AppearingPassageVersionView = ({
  rule,
  ruleVersion,
  passageVersion,
  index,
  innerRef,
  draggableProps,
  dragHandleProps,
  openPassageDialog,
  createPassageVersion,
  appearingPassageVersions,
  handlePassageVersionEdit,
  isMarkdownRendered,
}) => {
  const sx = useSx();
  const automaticEnrichment = rule.enrichmentFlow === 'auto';
  const showDragIcon =
    !passageVersion.previousVersion && ruleVersion.startsAt === passageVersion.startsAt;
  const [activePassageVersion, setActivePassageVersion] = useState({
    position: null,
    location: null,
  });

  const handleMouseEnter = (position, e) => {
    const { clientY, target } = e;
    const { top, bottom } = target.getBoundingClientRect();
    const middle = (top + bottom) / 2;
    const hoverLocation = clientY < middle ? 'above' : 'below';

    setActivePassageVersion({ position, location: hoverLocation });
  };

  const handleMouseLeave = () => setActivePassageVersion({ position: null, location: null });

  const newPosition = useMemo(() => {
    const { position, location } = activePassageVersion;
    const finalPosition = appearingPassageVersions.at(-1)?.passage?.position || 0;
    const isBelowLast = position === finalPosition && location === 'below';

    return isBelowLast ? position + 1 : position;
  }, [activePassageVersion, appearingPassageVersions]);

  const location = useLocation();
  const highlightIds = new URLSearchParams(location.search).getAll('highlightIds[]');
  const shouldBeHighlighted =
    highlightIds.includes(passageVersion.passage.id) || highlightIds.includes(passageVersion.id);

  return (
    <Box
      sx={sx.versionNode(shouldBeHighlighted)}
      ref={innerRef}
      data-testid={`passage-version-${index}`}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={(e) => handleMouseEnter(passageVersion.passage.position, e)}
      aria-label={shouldBeHighlighted ? 'highlighted passage' : 'passage'}
      {...draggableProps}
    >
      {!automaticEnrichment &&
        passageVersion.passage.position === activePassageVersion.position && (
          <Box sx={sx.separator(activePassageVersion.location)}></Box>
        )}
      <AddPassageContextMenu
        rule={rule}
        show={passageVersion.passage.position === activePassageVersion.position}
        position={newPosition}
        ruleVersion={ruleVersion}
        identifier={passageVersion.id}
        icon={<AddIcon fontSize="inherit" sx={sx.addIcon} aria-label="add passage button" />}
        sxProps={sx.addButton(activePassageVersion.location)}
      />
      <Box sx={{ ...sx.dragColumn }}>
        {showDragIcon && (
          <Box {...dragHandleProps} data-testid={`passage-version-drag-handle-${index}`}>
            <DragIndicatorIcon sx={sx.dragIcon(automaticEnrichment)} />
          </Box>
        )}
      </Box>
      <Box sx={[sx.contentColumn, carryForward(passageVersion, ruleVersion) && sx.carryForward]}>
        {isMarkdownRendered ? (
          <MarkdownPreview text={passageContent(passageVersion)} />
        ) : (
          <Box sx={sx.rawText} aria-label="raw text">
            {passageContent(passageVersion)}
          </Box>
        )}
      </Box>
      <Box sx={sx.defectColumn}>
        <DefectToolTip sx={sx.smallIcon} defects={passageVersion.defects} />
      </Box>
      <Box sx={sx.contextColumn} onClick={handleMouseLeave}>
        <AppearingPassageVersionContextMenu
          rule={rule}
          ruleVersion={ruleVersion}
          passageVersion={passageVersion}
          openPassageDialog={openPassageDialog}
          handlePassageVersionEdit={handlePassageVersionEdit}
          createPassageVersion={createPassageVersion}
        />
      </Box>
    </Box>
  );
};

const AppearingPassageVersionContextMenu = ({
  rule,
  ruleVersion,
  passageVersion,
  openPassageDialog,
  handlePassageVersionEdit,
  createPassageVersion,
}) => {
  const routes = useRoutes();
  const { id: ruleId, regulatorId } = rule;
  const ruleVersionId = ruleVersion.id;
  const automaticEnrichment = rule.enrichmentFlow === 'auto';

  const copyToClipboard = useCopyToClipboard();
  const handleCopy = (message, text) => copyToClipboard(text, message);
  const title = typeName(passageVersion);
  const subtitles = [
    passageVersion.number,
    `${passageVersion.startsAt}${passageVersion.endsAt ? ` - ${passageVersion.endsAt}` : ''}`,
  ];

  return (
    <ContextMenu dense identifier={passageVersion.id} title={title} subtitles={subtitles}>
      <MenuItem
        data-testid="passage-version-copy-passage-id"
        onClick={() =>
          handleCopy(`Copied ${parentTypeName(passageVersion)} ID`, passageVersion.passage?.id)
        }
      >
        <ListItemIcon>
          <CopyAllIcon />
        </ListItemIcon>
        <ListItemText>Copy {parentTypeName(passageVersion)} ID</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="passage-version-copy-id"
        onClick={() => handleCopy(`Copied ${typeName(passageVersion)} ID`, passageVersion.id)}
      >
        <ListItemIcon>
          <CopyAllIcon />
        </ListItemIcon>
        <ListItemText>Copy {typeName(passageVersion)} ID</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="passage-version-copy-content"
        onClick={() =>
          handleCopy(`Copied ${typeName(passageVersion)} Content`, passageContent(passageVersion))
        }
      >
        <ListItemIcon>
          <CopyAllIcon />
        </ListItemIcon>
        <ListItemText>Copy {typeName(passageVersion)} Content</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        component={Link}
        data-testid="passage-version-jump"
        to={routes.generateUrl('regulator.rule.ruleVersion', {
          regulatorId,
          ruleId,
          ruleVersionId: passageVersion.ruleVersion.id,
        })}
        disabled={ruleVersionId === passageVersion.ruleVersion.id}
      >
        <ListItemIcon>
          <FirstPageIcon />
        </ListItemIcon>
        <ListItemText>
          Go to Originating Rule Version ({passageVersion.ruleVersion.startsAt})
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        data-testid="passage-version-create"
        disabled={automaticEnrichment || !carryForward(passageVersion, ruleVersion)}
        onClick={() => createPassageVersion(typeName(passageVersion), passageVersion?.passage?.id)}
      >
        <ListItemIcon>
          <AddCircleOutlineIcon />
        </ListItemIcon>
        <ListItemText>Create Version</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="passage-version-edit"
        disabled={automaticEnrichment || carryForward(passageVersion, ruleVersion)}
        onClick={() => handlePassageVersionEdit(passageVersion)}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>Edit Version</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="passage-version-expire"
        onClick={() => openPassageDialog('expire', passageVersion)}
        disabled={
          automaticEnrichment ||
          !!passageVersion.nextVersion ||
          !carryForward(passageVersion, ruleVersion)
        }
      >
        <ListItemIcon>
          <LastPageIcon />
        </ListItemIcon>
        <ListItemText>Expire Version</ListItemText>
      </MenuItem>

      <MenuItem
        data-testid="passage-version-unexpire"
        onClick={() => openPassageDialog('unexpire', passageVersion)}
        disabled={automaticEnrichment || !!passageVersion.nextVersion || !passageVersion.endsAt}
      >
        <ListItemIcon>
          <RestoreIcon />
        </ListItemIcon>
        <ListItemText>Unexpire Version</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="passage-version-delete"
        disabled={automaticEnrichment || carryForward(passageVersion, ruleVersion)}
        onClick={() => openPassageDialog('delete', passageVersion)}
      >
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText>Delete Version</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        data-testid="passage-version-detach"
        disabled={automaticEnrichment || carryForward(passageVersion, ruleVersion)}
        onClick={() => openPassageDialog('detach', passageVersion)}
      >
        <ListItemIcon>
          <LinkOffIcon />
        </ListItemIcon>
        <ListItemText>Detach Version</ListItemText>
      </MenuItem>
    </ContextMenu>
  );
};

const useSx = () => {
  const fixedColumn = {
    display: 'flex',
    width: 24,
    flexShrink: 0,
    justifyContent: 'center',
    marginTop: '0.5em',
  };

  const halfMarginAbove = { marginTop: '0.5em' };
  const halfMarginBelow = { marginBottom: '0.5em' };

  return {
    versionNode: (shouldBeHighlighted) => ({
      display: 'flex',
      backgroundColor: shouldBeHighlighted ? 'yellow.900' : 'unset',
      borderColor: 'gray.300',
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: '1px',
      marginBottom: '-1px',
      position: 'relative',
      '&:last-child': {
        borderBottom: 0,
      },
    }),
    separator: (position) => ({
      backgroundColor: 'green.700',
      height: 2,
      width: '100%',
      position: 'absolute',
      top: position === 'above' ? -1 : 'unset',
      bottom: position === 'below' ? -1 : 'unset',
      filter: 'saturate(0%)',
    }),
    rawText: {
      marginTop: '.5em',
      whiteSpace: 'pre-wrap',
    },
    addIcon: {
      width: '14px',
      height: '14px',
      fontWeight: '900',
    },
    addButton: (position) => ({
      position: 'absolute',
      top: position === 'above' ? -10 : 'unset',
      bottom: position === 'below' ? -12 : 'unset',
      left: 0,
      transform: 'translateX(-50%)',
      backgroundColor: 'green.700',
      color: 'white',
      transition: 'filter 0.3s ease',
      border: '2px solid white',
      width: '22px',
      height: '22px',
      filter: 'saturate(0%)',
      ':hover': {
        filter: 'saturate(100%)',
        backgroundColor: 'green.700',

        '+ $separator': {
          filter: 'saturate(100%)',
          backgroundColor: 'green.700',
        },
      },
    }),
    dragColumn: {
      ...fixedColumn,
      marginTop: 0,
      flexDirection: 'column',
      alignItems: 'center',
    },
    dragIcon: (disabled) => ({
      width: 18,
      fill: disabled ? '#AFAFAF' : null,
    }),
    contentColumn: {
      flexGrow: 1,
      paddingLeft: '8px',
      // Targets the absolute first child, up to 10 levels deep
      ...makeArray(10).reduce((acc, _, idx) => ({
        ...acc,
        ['&' + ' > :first-child'.repeat(idx + 1)]: halfMarginAbove,
      })),
      // Targets the absolute last child, up to 10 levels deep
      ...makeArray(10).reduce((acc, _, idx) => ({
        ...acc,
        ['&' + ' > :last-child'.repeat(idx + 1)]: halfMarginBelow,
      })),
    },
    carryForward: {
      color: 'gray.500',
    },
    defectColumn: {
      ...fixedColumn,
    },
    contextColumn: {
      ...fixedColumn,
    },
    smallIcon: {
      width: 18,
    },
  };
};

export default AppearingPassageVersionView;
